module.exports = {
  siteTitle: 'Logicket CRM', // <title>
  manifestName: 'Logicket',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Logicket is coming soon',
  subHeading:
    "Streamline the way you operate your service business. Share your email below to get updates on our progress.",
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:dan@logicket.com',
    },
  ],
};
